

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
//import { Autocomplete } from 'stimulus-autocomplete'
//mport StimulusReflex from 'stimulus_reflex'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
//StimulusReflex.initialize(application)

//application.register('autocomplete', Autocomplete)

