

import { Application } from "stimulus"

import { Autocomplete } from 'stimulus-autocomplete'
//mport StimulusReflex from 'stimulus_reflex'

const application = Application.start()

application.register('autocomplete', Autocomplete)

import {  
    safeStringify,    
    setImdbId    
  } from "helpers";
  
export default class extends Autocomplete {
    onSelect(selected, textValue, value){
       // console.log("selected "+safeStringify(selected)+" textValue:"+textValue+" value:"+value);
        //super.onSelect(selected, textValue, value);
        var url=setImdbId(value)
        window.location.href="/title/"+url

    }
    

    initialize() {
      //  console.log("Autocomplete init ");
      // const autocomplete_trigger = $('#autocomplete_trigger')[0]
      // if (autocomplete_trigger){         
      //   this.autocomplete_trigger = autocomplete_trigger
      // }
       super.initialize()

    }
    onInputBlur() {
    //  console.log("Autocomplete onInputBlur ");
      super.onInputBlur();
    } 
    reinit = event => {
    //  console.log("reinit !")
      super.initialize()
    }


}