/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import $ from 'jquery';

import initScreenFull from './modules/common/fullscreen';
import initLoadCSS from './modules/common/load-css';
import initTranslation from './modules/common/localize';
import initNavbarSearch from './modules/common/navbar-search';
import initSidebar from './modules/common/sidebar';
import initTableCheckAll from './modules/common/table-checkall';
import initTriggerResize from './modules/common/trigger-resize';
import popper from  './modules/common/popper';
//import BlinkSDK from './modules/blink-sdk';

import "is_js"

//require('popper.js/dist/umd/popper');

import 'blockui';
import { StateToggler, initToggleState } from './modules/common/toggle-state';
import { initCardDismiss, initCardCollapse, initCardRefresh } from './modules/common/card-tools';
import initBootstrap from './modules/common/bootstrap-start';
//var SameHeight = require('same-height');

//import "md5"
//var md5 = require('md5');


import {

   // setSameHeight,
    setCheckExist,
    setTopSearch,
    booster
  } from "helpers";
  


export default function initApp() {
    var new_ext_is_installed = false;
    
    // Init modules
    initBootstrap();
    initScreenFull();
    initLoadCSS();
    initTranslation();
    initNavbarSearch();
    initSidebar();
    initTableCheckAll();
    initTriggerResize();
    initToggleState();
    // card tools
    initCardDismiss();
    initCardCollapse();
    initCardRefresh();
    booster();
    setTopSearch();
    //SameHeight.init("sameheight");
    //Dropzone.autoDiscover = false;

   // setSameHeight();

    // Restore body classes
    // -----------------------------------
    var $body = $('body')


   // $(document).ajaxStart(    
   //   blockTable() 
   //   //$.blockUI(
   //   //  { message:'<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'}
   //   //  )
   //     ).ajaxStop(
   //      unblockTable()
   //   );
//

    new StateToggler().restoreState("body","layout-h");
    new StateToggler().restoreState("#topnavstretcher","container");

    // enable settings toggle after restore
    $('#chk-fixed').prop('checked', $body.hasClass('layout-fixed'));
    $('#chk-collapsed').prop('checked', $body.hasClass('aside-collapsed'));
    $('#chk-collapsed-text').prop('checked', $body.hasClass('aside-collapsed-text'));
    $('#chk-boxed').prop('checked', $body.hasClass('layout-boxed'));
    $('#chk-float').prop('checked', $body.hasClass('aside-float'));
    $('#chk-hover').prop('checked', $body.hasClass('aside-hover'));

    // When ready display the offsidebar
    $('.offsidebar.d-none').removeClass('d-none');
    //$("#switch_style")
    $("#season_select_input").prop("selectedIndex", 0)
  


  function blockTable() {
    return $('#datatableContainer').block({ message: null });
  }
  function unblockTable(){
    return $('#datatableContainer').unblock();
  }
  $(document).on("click","a[rel='external'], a.external",function(e){
		this.target = "_blank";
	});

  $(document).ready(function(e){

    $("#topnavstretcher").fadeIn();
 
    $('.search-panel .dropdown-menu').find('a').click(function(e) {
		  e.preventDefault();		
		  var search_txt = $(this).html();
      $('.search-panel span.search_what').html(search_txt);
      
      var what = this.getAttribute("data-what");
      if (what=="movie"){
        $("#tv_autocomplete_trigger").hide();
        $("#all_autocomplete_trigger").hide();
        $("#movies_autocomplete_trigger").show();

      }  
      else if(what=="tvshow"){
        $("#tv_autocomplete_trigger").show();
        $("#all_autocomplete_trigger").hide();
        $("#movies_autocomplete_trigger").hide();
      }
      else{
        $("#tv_autocomplete_trigger").hide();
        $("#all_autocomplete_trigger").show();
        $("#movies_autocomplete_trigger").hide();
      }
	});
});
  
}
