// If jQuery is not already registered in window.jQuery do it here.
window.jQuery = window.$ = require('jquery')
 
//const DataTable = require('datatables.net');

//import DataTable from 'datatables.net'


//require('datatables.net-bs')


//var DataTable = require( 'datatables.net' )
//require('datatables.net-bs4')

// The controller will call: window.jQuery(table).DataTable(config)
//var $ = require( 'jquery' );
//require( 'datatables.net-dt' )( window, $ );


//import DataTable from 'datatables.net'


// 
//$.fn.DataTable = function ( opts ) {
//    return $(this).dataTable( opts ).api();
//};


// These examples use bootstrap4 and the scroller plugin.
// See https://datatables.net/download for more options.
require('datatables.net')
require('datatables.net-bs4')
require('datatables.net-scroller-bs4')
//require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
//import DataTable from 'datatables.net-bs4'

// Stimulus setup.
import { Application } from 'stimulus'
const application = Application.start()


import DataTable from 'stimulus-datatables'

//application.register('datatable', DataTables)


// The controller will call: window.jQuery(table).DataTable(config)
//require('datatables.net')
 
// These examples use bootstrap4 and the scroller plugin.
// See https://datatables.net/download for more options.
//require('datatables.net-bs')
//require('datatables.net-bs4')
//require('datatables.net-responsive-net')
//require('datatables.net-scroller-bs4')
//require('../packs/isubdb/modules/blink-sdk')
 
//import DataTable from 'datatables.net'
 
import 'select2';
import 'blockui';
import 'select2/dist/css/select2.min.css';
//import Storages from 'js-storage';
import { initNotify } from '../packs/isubdb/modules/elements/notify';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
import initTranslation from '../packs/isubdb/modules/common/localize';
import initBootstrap from '../packs/isubdb/modules/common/bootstrap-start';

//import BlinkSDK from '../packs/isubdb/modules/blink-sdk';

//import swal from 'sweetalert';
import url from 'url-parameters';
var is = require("is_js");
import Rails from "@rails/ujs";
var mdt;
var myCntr=0;
var csrf;
var blinkSDK;
import {  
  safeStringify,  
  setImdbId,
  addZero,
  chromepop,
  select_sponsor,
  limit_reached,
  fetch_file,
  getBlink,
  file_download,
  post_data,
  setToken,
  downloadSubtitle,
  request_data

} from "helpers";

// Register the stimulus-datatables controller.

//application.register('datatable', Datatable)

//import DataTable from 'stimulus-datatables'

//import DataTable from 'rails-datatables'
//import { chromepop } from '../helpers'

export default class extends DataTable {

  initComplete ( settings, json ){
    this.debug(`hello ! initComplete `)
    super.initComplete(settings, json)

  }


  initialize() {
    // Ignore ghost events.
    if (!this.isBooting()) return
    
    // Default settings here will be overridden by component configurations.
   // this.config = { pagingType: 'simple', debug: true }
  //  this.config = { pagingType: 'full_numbers', debug: true }
    
  
  setToken()
  blinkSDK=getBlink()

    // serverSide: true,
    //
    // ajax: {
    //    type: 'post',
    //    url: opensubtitles_url,
    //    data: {
    //       tkn: token,
    //       languages: languages
    //       
    //    }
    // },
    
     
    
    //this.debug(`hello ! initialize this: ${safeStringify(this)}`)
   this.debug(`hello ! initialize `)
    
    // Call the super method which gets the component configuration.
     // Link to the scrolling checkbox and back.
     mdt=null
     myCntr=0
     const season_select = $('#season_select_input')[0]
     if (season_select){
      season_select.dt = this
       this.season_select = season_select
     }

     const episode_select = $('#episode_select_input')[0]
     if (episode_select){
      episode_select.dt = this
       this.episode_select = episode_select
     }


    const hi_select = $('#hi_select_input')[0]
    if (hi_select){
      hi_select.dt = this
      this.hi_select = hi_select
    }

    const hd_select = $('#hd_select_input')[0]
    if (hd_select){
      hd_select.dt = this
      this.hd_select = hd_select
    }
    const trusted_select = $('#trusted_select_input')[0]
    if (trusted_select){
      trusted_select.dt = this
      this.trusted_select = trusted_select
    }
    const fpo_select = $('#fpo_select')[0]
    if (fpo_select){
      fpo_select.dt = this
      this.fpo_select = fpo_select
    }

    const language_select = $('#language_select_input')[0]
    if (language_select){
      language_select.dt = this
      this.language_select = language_select
    }

    const provider_select = $('#provider_select_input')[0]
    if (provider_select){
      provider_select.dt = this
      this.provider_select = provider_select
    }

    const moviehash_input = $('#moviehash_text_input')[0]
    if (moviehash_input){
      moviehash_input.dt = this
      this.moviehash_input = moviehash_input
    }
    const filename_input = $('#filename_text_input')[0]
    if (filename_input){
      filename_input.dt = this
      this.filename_input = filename_input
    }

    const title_input = $('#title_h_input')[0]
    if (title_input){
      title_input.dt = this
      this.title_input = title_input
    }
    const imdbid_input = $('#imdbid_h_input')[0]
    if (imdbid_input){
      imdbid_input.dt = this
      this.imdbid_input = imdbid_input
    }

    const episode_imdbid_input = $('#episode_imdbid_h_input')[0]
    if (episode_imdbid_input){
      episode_imdbid_input.dt = this
      this.episode_imdbid_input = episode_imdbid_input
    }

    const year_input = $('#year_h_input')[0]
    if (year_input){
      year_input.dt = this
      this.year_input = year_input
    }

    const hidden_languages_input = $('#hidden_languages_input')[0]
    if (hidden_languages_input){
      hidden_languages_input.dt = this
      this.hidden_languages_input = hidden_languages_input
   
    }

    

     //this.config.dom = 'lfriptrip'
     // Get the table config and the scrolling state.
     
     super.initialize()
     //this.setScrollingState()
     
   
     
    

    // This sets the final config values.
    
  }
  toggle_scrolling = event => {
    const dtd = this.element.dt
    this.debug("called toggle_scrolling")
    if (dtd) {
      this.debug("dt is on")
      dtd.setScrollingState()
      dtd.teardown() // This triggers a reconnect.
    }
  }
  toggle_hearing = event => {
    const dtd = this.element.dt
    this.debug("called toggle_hearing")
    if (dtd) {
      this.debug("dt is on")
      dtd.setHearingState()
      dtd.teardown() // This triggers a reconnect.
    }
  }
  select_hd  = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      var hd_value = this.element.selectedOptions[0].getAttribute("value")
      this.debug("select_hd - dt is on, hd_value:"+hd_value)
      mtd.setHdSelect(hd_value)
      mtd.teardown() // This triggers a reconnect.
    }
  }
  select_season = event => {
    const mtd = this.element.dt
    
    if (mtd) {
      var season_value = this.element.selectedOptions[0].getAttribute("value")
      var episodes = this.element.selectedOptions[0].getAttribute("data-episodes")
      this.debug("select_season - dt is on, season_value:"+season_value+" episodes:"+episodes)
      mtd.setSeason(season_value, episodes)
      //mtd.teardown() // This triggers a reconnect.
    }
  }
  select_episode = event => {
    const mtd = this.element.dt
    
    if (mtd) {
      var episode_value = this.element.selectedOptions[0].getAttribute("value")
      
      this.debug("select_episode - dt is on, episode_value:"+episode_value)
      mtd.setEpisode(episode_value)
      mtd.teardown() // This triggers a reconnect.
    }
  }



  select_trusted = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      var trusted_value = this.element.selectedOptions[0].getAttribute("value")
      this.debug("select_trusted - dt is on, trusted_value:"+trusted_value)
      mtd.setTrustedSelect(trusted_value)
      mtd.teardown() // This triggers a reconnect.
    }
  }
  
  select_fpo = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      var select_fpo = this.element.selectedOptions[0].getAttribute("value")
      this.debug("select_trusted - dt is on, select_fpo:"+select_fpo)
      mtd.setFpo(select_fpo)
      mtd.teardown() // This triggers a reconnect.
    }
  }

  select_hearing = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      var hh_value = this.element.selectedOptions[0].getAttribute("value")
      this.debug("select_hearing - dt is on, hh_value:"+hh_value)
      mtd.setHearingSelect(hh_value)
      mtd.teardown() // This triggers a reconnect.
    }
  }
  select_provider = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      var p_value = this.element.selectedOptions[0].getAttribute("value")
      
      
      this.debug("select_provider - dt is on, p_value:"+p_value)
      mtd.setProvider(p_value)
      mtd.setQueryLinks()
      this.debug('setQueryLinks in set provider')
      mtd.teardown() // This triggers a reconnect.
    }
  }
  select_language = event => {
    const mtd = this.element.dt
    //this.debug("called select_hearing "+this.element.selectedOptions[0].getAttribute("value"))
    if (mtd) {
      //var ln_value = this.element.selectedOptions[0].getAttribute("value")
      var ln_value = $(this.element).select2('data')
      var p_value = this.element.selectedOptions[0].getAttribute("value")
      var langs=[]
      ln_value.forEach(function(obj){
        langs.push(obj["id"])
      })
      var ln_string=langs.join()
      sessionStorage.setItem("search_languages",ln_string );
      this.debug("select_hearing - dt is on, setItem: langs:"+ln_string)
      mtd.setProvider(p_value)
      mtd.setLanguageSelect(langs)
      mtd.teardown() // This triggers a reconnect.
    }
  }


  
  

  set_moviehash = event => {
    const mtd = this.element.dt
    var moviehash = $('#moviehash_text_input').val()
    //this.element.getAttribute('data-moviehash')
    var file_name = $('#filename_text_input').val()
    var search_languages = sessionStorage.getItem("search_languages")
    if (search_languages==undefined){      
      search_languages=document.getElementsByTagName('html')[0].getAttribute('lang')
    }
    var languages = search_languages.split(",")
   
    //this.element.getAttribute('data-filename')
 //  this.debug("called set moviehash:"+moviehash+" file name:"+file_name)
    if (mtd) {
      //alert("am i crazy")
      
      //mtd.setMyMoviehash(moviehash,file_name)
      //mtd.setQueryLinks()
      var p_value = mtd.provider_select.selectedOptions[0].getAttribute("value")
      
      
      


      
      mtd.setQueryLinks()
      this.debug('setQueryLinks in set moviehash')
      mtd.setLanguageSelect(languages)
      mtd.setProvider(p_value)
      //mtd.setLanguageSelect("en,fr,es")
      mtd.teardown()
      this.debug("set_moviehash - dt is on,  moviehash:"+moviehash+" filename:"+file_name+" p_value:"+p_value)
    }



  }

  install_extension = event => {
    document.getElementById("sponsepop").style.display = "none";
    var extid = $("#chromepop_ext_id").val();
    //alert("install extension")
    chromepop(extid)
  }

  open_subscription = event => {
    document.getElementById("sponsepop").style.display = "none";
    //alert("open subscrition")
    blinkSDK.createPG(document.body, "subscribe", "popup");
  }
  
  download_subtitle = event => {
    var elem=$(this.element)
    elem.children("em").removeClass("icon-download")
    elem.children("em").addClass("icon-download-1")
    //elem.children("em").html('<div class="ball-clip-rotate"><div></div></div>')
    this.element.setAttribute("disabled",true)
    //.html("...")
    
    var fileid =this.element.getAttribute('data-fileid')
    var file_name =this.element.getAttribute('data-filename')
    var file_url =this.element.getAttribute('data-fileurl')
    
    var tkn =this.element.getAttribute('data-tkn')
    var ext_installed = sessionStorage.getItem("ext_installed")
    var extid = this.element.getAttribute('data-extcode')
    var ffextid = this.element.getAttribute('data-ffcode')
    //this.debug("download_subtitle "+fileid+" html:"+elem.html())
    //var provider = this.provider_select.selectedOptions[0].getAttribute("value")
    var provider = document.getElementById('provider_select_input').selectedOptions[0].getAttribute("value")
    var that = this

    this.debug("download_subtitle "+fileid+" ext installed:"+ext_installed+" extid:"+extid)
    
    var can_download = false
    var limit=3
    var alreadyDl=1
    var reached_limit=false
    if (!sessionStorage.getItem("lcntr")) {
      sessionStorage.setItem("lcntr",1);
    }
    else{
      alreadyDl=parseInt(sessionStorage.getItem("lcntr"))
      sessionStorage.setItem("lcntr",alreadyDl+1);
    }

    //if ((is.chrome() || is.opera()) && is.not.mobile()  ){
    //if (ext_installed=="1"){




    if (blinkSDK.isSubscribed() || ext_installed=="1") {        
        limit=100        
        if (alreadyDl<limit){
          can_download=true
        }
        else{
          reached_limit=true
        }
    }
    //}
    else {      
      if (alreadyDl<limit){
        can_download=true
      }     
    }

    this.debug("download_subtitle can_download:"+can_download+" alreadyDl:"+alreadyDl+" limit:"+limit)
    

    if (reached_limit){
      limit_reached()
    }
    else{
      if (can_download){
        sessionStorage.removeItem("blocked_provider" );
        sessionStorage.removeItem("blocked_fileid" );
        sessionStorage.removeItem("blocked_file_url" );
        sessionStorage.removeItem("blocked_file_name" );
        sessionStorage.removeItem("blocked_tkn" );
        downloadSubtitle(provider,fileid, file_url,file_name,tkn,limit)
      }
      else{
        sessionStorage.setItem("blocked_provider",provider );
        sessionStorage.setItem("blocked_fileid",fileid );
        sessionStorage.setItem("blocked_file_url",file_url );
        sessionStorage.setItem("blocked_file_name",file_name );
        sessionStorage.setItem("blocked_tkn",tkn );
      //chromepop(extid)
        if ((is.chrome() || is.opera()) && is.not.mobile()  ){        
          select_sponsor(extid)
        }
        else{
          blinkSDK.createPG(document.body, "subscribe", "popup");
        }
      }

    }
  }
    
  download_subtitle_ext = event => {
    var elem=$(this.element)
    elem.children("em").removeClass("icon-download")
    elem.children("em").addClass("icon-download-1")
    //elem.children("em").html('<div class="ball-clip-rotate"><div></div></div>')
    this.element.setAttribute("disabled",true)
    //.html("...")
    
    var fileid =this.element.getAttribute('data-fileid')
    var file_name =this.element.getAttribute('data-filename')
    var file_url =this.element.getAttribute('data-fileurl')
    
    var tkn =this.element.getAttribute('data-tkn')
    var ext_installed = "1"
    //sessionStorage.getItem("ext_installed")
    var extid = this.element.getAttribute('data-extcode')
    var ffextid = this.element.getAttribute('data-ffcode')
    //this.debug("download_subtitle "+fileid+" html:"+elem.html())
    //var provider = this.provider_select.selectedOptions[0].getAttribute("value")
    var provider = document.getElementById('provider_select_input').selectedOptions[0].getAttribute("value")
    var that = this

    this.debug("download_subtitle "+fileid+" ext installed:"+ext_installed+" extid:"+extid)
    
    var can_download = false
    if ((is.chrome() || is.opera()) && is.not.mobile()  ){
      if (ext_installed=="1"){
        can_download = true
      }
    }
    else {
      can_download = true
    }

    if (can_download){
      sessionStorage.removeItem("blocked_provider" );
      sessionStorage.removeItem("blocked_fileid" );
      sessionStorage.removeItem("blocked_file_url" );
      sessionStorage.removeItem("blocked_file_name" );
      sessionStorage.removeItem("blocked_tkn" );
      downloadSubtitle(provider,fileid, file_url,file_name,tkn)
    }
    else{
      sessionStorage.setItem("blocked_provider",provider );
      sessionStorage.setItem("blocked_fileid",fileid );
      sessionStorage.setItem("blocked_file_url",file_url );
      sessionStorage.setItem("blocked_file_name",file_name );
      sessionStorage.setItem("blocked_tkn",tkn );
      sessionStorage.setItem("blocked_extid",extid);
      chromepop(extid)
    }
  }


  setMyMoviehash = (moviehash, file_name) => {
    var config = this.config || {}
    
    this.debug(`setMyMoviehash  moviehash:${moviehash} config.ajax :${safeStringify(config.ajax)} `)
    //config.ajax.data.moviehash = moviehash
    config.ajax.url = "/search/moviehash/"+moviehash
    config.ajax.data.query = file_name
    
  }
  setSeason  = (season_number,episodes) => {
    $('#episode_select_input').empty()
    this.debug(`setSeason  season_number:${season_number} episodes:${episodes}`)
   // var keys 
   // keys = Object.keys(episodes);
   // console.log("keys:"+keys)
   // for(var i in keys){
        //doSomething(episodes[keys[i]].id);
       // console.log("key:"+i+" value:"+episodes[keys[i]]+" id:"+episodes[keys[i]].id)
   // }
   // var option = $('<option>', { value: 'the_value', text: 'some text' }).data('misc', 'misc-value);
   var empty = $('<option>', { value: "", text: "" }) //.data('misc', 'misc-value);
   $('#episode_select_input').append(empty);

   $.each(JSON.parse(episodes), function (name, value) {
    //$('#result3').append('<div>' + name + "=" + value + '</div>');
   // console.log("name:"+name+" value:"+value)
    var option = $('<option>', { value: value, text: name }) //.data('misc', 'misc-value);
    $('#episode_select_input').append(option);
  });

  }

  

  setEpisode = (episode_imdbid) => {
    const config = this.config || {}
    var provider = this.provider_select.selectedOptions[0].getAttribute("value")
    var episode_ttimdbid = setImdbId(episode_imdbid)
    
    this.debug("set episode provider:"+provider+" episode_imdbid:"+episode_imdbid)
    this.imdbid_input.setAttribute("value",episode_imdbid)
    this.episode_imdbid_input.setAttribute("value",episode_imdbid)
    this.imdbid_input.setAttribute("data-ttimdbid",episode_ttimdbid)
    this.setEpisodeDetails(episode_ttimdbid)
    var current_path=window.location.pathname
    var new_path_array=current_path.split("/")
    new_path_array[new_path_array.length-1]=episode_ttimdbid
    var new_path=new_path_array.join("/")
    this.debug("old_path:"+current_path+" new_path:"+new_path+" new_path_array:"+safeStringify(new_path_array))


    history.pushState({page: episode_ttimdbid }, episode_ttimdbid , new_path)

    this.debug('setQueryLinks in set episode')
    this.setQueryLinks()
    this.setProvider(provider)
   // config.ajax.url = "/search/imdb/"+episode_imdbid

  }
  setQueryLinks = () => {


    


    
    //config.ajax.data.csrf_token = csrf
  //  var provider = this.provider_select.selectedOptions[0].getAttribute("value")
    var title = this.title_input.getAttribute("value")
    var imdbid = this.imdbid_input.getAttribute("value")
    var ttimdbid = this.imdbid_input.getAttribute("data-ttimdbid")
    var ttimdbid7 = setImdbId(imdbid,true,7)
    var year = this.year_input.getAttribute("value")
    var opensubtitles_url, subscene_url, yify_url , addicted_url, podsnapisi_url,  podsnapisi_imdb_url, season_number, episode_number
    //var moviehash = this.moviehash_input.getAttribute("value")
    //var filename = this.filename_input.getAttribute("value")
    var moviehash = $('#moviehash_text_input').val()    
    var filename = $('#filename_text_input').val()
    
    if (this.season_select){
      this.debug("setQueryLinks TV show  moviehash:"+moviehash+" filename:"+filename+" ttimdbid:"+ttimdbid+" ttimdbid7:"+ttimdbid7+" imdbid:"+imdbid+" title:"+title)
      $("#option_yifysubs").hide();
      $("#option_addicted").show();
      season_number = this.season_select.selectedOptions[0].getAttribute("value")
      episode_number = this.episode_select.selectedOptions[0].text
      opensubtitles_url = "/search/imdb/"+imdbid
      yify_url = "/search/yifysubs/"+ttimdbid7
      subscene_url = "/search/subscene?query="+ title+ " S"+addZero(season_number)+" E"+addZero(episode_number)
      podsnapisi_url= "/search/podnapisi/title?query="+title+"&season_number="+season_number+"&episode_number="+episode_number
      podsnapisi_imdb_url= "/search/podnapisi/imdb/"+ttimdbid7+"/?season_number="+season_number+"&episode_number="+episode_number
      addicted_url="/search/addicted?query="+title+"&season_number="+season_number+"&episode_number="+episode_number
    }
    else if (moviehash!=""){
      $("#option_yifysubs").show();
      $("#option_addicted").show();
      this.debug("setQueryLinks mofviehash moviehash:"+moviehash+" filename:"+filename)
      opensubtitles_url = "/search/moviehash/"+moviehash+"?query="+filename
      yify_url = "/search/yifysubs/"+ttimdbid7
      subscene_url = "/search/subscene?query="+ title+ " "+year
      podsnapisi_url= "/search/podnapisi/title?query="+title+"&year="+year+"&moviehash="+moviehash
      podsnapisi_imdb_url= "/search/podnapisi/imdb/"+ttimdbid7+"&moviehash="+moviehash

      addicted_url="/search/addicted?query="+filename

    }
    else if (filename!=""){
      this.debug("setQueryLinks mofviehash moviehash:"+moviehash+" filename:"+filename)
      opensubtitles_url = "/search/moviehash/"+moviehash+"?query="+filename
      yify_url = "/search/yifysubs/"+ttimdbid7
      subscene_url = "/search/subscene?query="+ filename
      podsnapisi_url= "/search/podnapisi/title?query="+title+"&year="+year
      podsnapisi_imdb_url= "/search/podnapisi/imdb/"+ttimdbid7

      addicted_url="/search/addicted?query="+filename
    }
    else{
      this.debug("setQueryLinks MOVIE")
      $("#option_yifysubs").show();
      $("#option_addicted").hide();
      opensubtitles_url = "/search/imdb/"+imdbid
      subscene_url="/search/subscene?query="+ title+ " "+year
      yify_url = "/search/yifysubs/"+ttimdbid7
      podsnapisi_url= "/search/podnapisi/title?query="+title+"&year="+year
      podsnapisi_imdb_url= "/search/podnapisi/imdb/"+ttimdbid7
      addicted_url="/search/addicted?query="+title

    }



   
  //  this.debug("setQueryLinks opensubtitles_url:"+opensubtitles_url)
  //  this.debug("setQueryLinks subscene_url:"+subscene_url)
  //  this.debug("setQueryLinks podsnapisi_url:"+podsnapisi_url)
  //  this.debug("setQueryLinks yify_url:"+yify_url)
  //  this.debug("setQueryLinks addicted_url:"+addicted_url)
  //  this.debug("setQueryLinks podsnapisi_imdb_url:"+podsnapisi_imdb_url)
//

    $("#option_subscene").attr("data-queryurl",subscene_url)
    $("#option_opensubtitles").attr("data-queryurl",opensubtitles_url)    
    $("#option_yifysubs").attr("data-queryurl",yify_url)
    $("#option_addicted").attr("data-queryurl",addicted_url)

    $("#option_podnapisi").attr("data-queryurl",podsnapisi_url)
    $("#option_podnapisi_imdb").attr("data-queryurl",podsnapisi_imdb_url)
  //  this.setProvider(provider)
  }


  setProvider = (provider) => {
    const config = this.config || {}
   // const mdt = this.element.dt

//    let mdt =  $('#subtitles-table').DataTable()
    
    var url = document.getElementById('provider_select_input').selectedOptions[0].getAttribute('data-queryurl')
    var encoded_url = encodeURI(url)
    
  
    //config.ajax.data.moviehash = moviehash
    if (provider == "opensubtitles"){
      config.serverSide = true
      config.pageLength  =25
    // mdt.columns(4).visible(true)
    // mdt.columns(5).visible(true)
    // mdt.columns(6).visible(true)
    }


    else if (provider =="yifysubs"){
      config.serverSide = true
      config.pageLength  =100
    }

    else{      
      config.serverSide = false
      config.pageLength  = 25
      
    }
   // config.debug = false
    config.ajax=config.nAjax
    config.data=null
    config.ajax.url = encoded_url
    config.ajax.tkn = config.tkn
    
    config.ajax.type = "post"
    csrf = sessionStorage.getItem("token")
    config.ajax.headers = {
      "X-CSRF-Token": csrf
    } 
    config.nAjax=config.ajax
      
    
    
   // this.debug("set provider:"+provider+" mdt :"+safeStringify(mdt.columns))
    this.debug(`setProvider csrf:${csrf} url:${url} encoded_url:${encoded_url} config :${safeStringify(config)} `)
    
  }

  setHearingSelect = (hh_value) => {
  //  const hearing = this.hi_value()
    const config = this.config || {}
    this.debug("called setHearingSelect hearing:"+hh_value)
    //var r = "exclude"
    //if (hearing){
    //  r = "only"
    //} 
    if (config.ajax==null) {
      config.data=null
      config.ajax=config.nAjax
    }
    config.ajax.data.hearing_impaired = hh_value
    config.ajax.data.page = 1
     //this.debug("called setHearingState hearing:"+hearing+" r:"+r)
  }
  setLanguageSelect = (langs) => {
    //  const hearing = this.hi_value()
      const config = this.config || {}
      this.debug("called setLanguageSelect langs:"+langs+" config:"+safeStringify(config))
      //var r = "exclude"
      //if (hearing){
      //  r = "only"
      //} 
      if (config.ajax==null) {
        config.data=null
        config.ajax=config.nAjax
      }
      //config.ajax.data={}

      config.ajax.data.languages = langs
      config.ajax.data.page = 1
       //this.debug("called setHearingState hearing:"+hearing+" r:"+r)
    }

 
  setHdSelect = (hd_value) => {    
      const config = this.config || {}
      this.debug("called setHdSelect hd:"+hd_value) 
      if (config.ajax==null) {
        config.data=null
        config.ajax=config.nAjax
      }
      config.ajax.data.hd = hd_value    
      config.ajax.data.page = 1   
    }
    setTrustedSelect = (trusted_value) => {    
      const config = this.config || {}
      this.debug("called setTrustedSelect trusted:"+trusted_value) 
      if (config.ajax==null) {
        config.data=null
        config.ajax=config.nAjax
      }
      config.ajax.data.from_trusted = trusted_value
      config.ajax.data.page = 1       
    }
    setFpo = (fpo_value)=> {    
      const config = this.config || {}
      this.debug("called setFpo trusted:"+fpo_value) 
      if (config.ajax==null) {
        config.data=null
        config.ajax=config.nAjax
      }
      config.ajax.data.foreign_parts_only = fpo_value
      config.ajax.data.page = 1       
    }

  setHearingState = () => {
    const hearing = this.checkbox_h && this.checkbox_h.checked
    const config = this.config || {}
    //this.debug("called setHearingState hearing:"+hearing+" ajax:"+config.ajax.data.languages)
    var r = "exclude"
    if (hearing){
      r = "only"
    }
    if (config.ajax==null) {
      config.data=null
      config.ajax=config.nAjax
    }
    config.ajax.data.hearing_impaired = r
     this.debug("called setHearingState hearing:"+hearing+" r:"+r)
  }
 
  setScrollingState = () => {
    const scrolling = this.checkbox && this.checkbox.checked
    const config = this.config || {}
    config.scroller = scrolling
    config.scrollY = scrolling ? 600 : undefined
    config.stateSave = !scrolling
  }
 get hi_sel() {
   return this.targets.find('hi_select')
 }
 get hi_value(){
   return this.hi_sel.selectedOptions[0].getAttribute("value")
 }

 setEpisodeDetails = (ttimdbid) => {
  var that = this
  if (ttimdbid){
    //$('#episode_details').block({ message: null });
    
      var requrl = "/details/"+ttimdbid+"?eo=1"
      request_data(requrl, function (response) {
        that.debug("called setEpisodeDetails requrl:"+requrl+" response:"+safeStringify(response))
        $("#episode_details .card-body").html(response)    
      //  $('#episode_details').unblock()
        //setSameHeight()  
      });
   
  }

 }
 setMovieDetails = (data) => {
    //if (!this.isBooting()) return
    var that = this
  //  that.debug("setMovieDetails with data:"+safeStringify(data))
    if (data.featureDetails){
      $('#movie_details').block({ message: null });
      var imdbid= data.featureDetails.imdb_id
      var ttimdbid=data.formatttedImdbid
      var year= data.featureDetails.year
      var title= data.featureDetails.title
      if (data.featureDetails.feature_type=="Episode"){
        title = data.featureDetails.parent_title
      }
     // that.debug("setMovieDetails details for imdbid:"+imdbid+" ttimdbid:"+ttimdbid+" year:"+year+" title:"+title+" type:"+data.featureDetails.feature_type+" data:"+safeStringify(data));
     that.debug("setMovieDetails details for imdbid:"+imdbid+" ttimdbid:"+ttimdbid+" year:"+year+" title:"+title+" type:"+data.featureDetails.feature_type);
      this.imdbid_input.setAttribute("data-ttimdbid",ttimdbid)
      this.imdbid_input.setAttribute("value",imdbid)
      this.title_input.setAttribute("value",title)
      this.year_input.setAttribute("value",year)
      
      var requrl = "/details/"+ttimdbid
      request_data(requrl, function (response) {
    //    that.debug("called setMovieDetails requrl:"+requrl+" response:"+safeStringify(response))
        $("#movie_details .card-body").html(response)    
        $('#movie_details').unblock()
        //setSameHeight()  
      });
      this.setQueryLinks()
      this.debug('setQueryLinks in set movie details')
    }
 }
 openFirstInfo = () => {
  var that = this
  
    var infobtns = $('tr td.details-control em')
    if (infobtns.length>0){
      $(infobtns[0]).click()
      that.debug("openFirstInfo --- CLICK");
    }
    else{
      that.debug("openFirstInfo no info loaded");
    }
 }
 afterTableInitialization =  (settings,json) => {
   this.debug("here we go, after initialization")
   this.openFirstInfo()
 }
 setRowsVisibility  = (mdt) => {
  const that = this
  
  that.debug("setRowsVisibility ->"+safeStringify( mdt.init().columnDefs ))
  if (is.mobile()){
    that.debug("mobile screen ! remove columns ->"+mdt.columns.size)
    //if (mdt.columns.size>3){
      mdt.columns(3).visible(false)
      mdt.columns(4).visible(false)
      mdt.columns(5).visible(false)
      mdt.columns(6).visible(false)
      mdt.columns(7).visible(false)
      mdt.columns(8).visible(true)    
      //mdt.columns.adjust()
    //}
  }
  else{
    mdt.columns(3).visible(true)
    mdt.columns(4).visible(true)
    mdt.columns(5).visible(true)
    mdt.columns(6).visible(true)
    mdt.columns(7).visible(true)
    mdt.columns(8).visible(true)    
    //mdt.columns.adjust()
  }

 }

 setChildRows = () => {
  this.debug("setChildRows  start "+myCntr)

  //const config = this.config || {}
  //config["ajax"]=config["nAjax"]
  //csrf = sessionStorage.getItem("token")
  //config.ajax.headers = {
  //  "X-CSRF-Token": csrf
  //} 
//
  //var mdt = this.element.dt
  const that = this
  
  if (mdt==null){
    this.debug("setChildRows  mdt is null ")
  
    mdt =  $('#subtitles-table').on('error.dt', function (e, settings, techNote, message) {
      
      that.log("child rows response:"+e.type+" settings:"+settings+" techNote:"+techNote+"  message:"+message)
      $["notify"].closeAll() 
      $.notify({
          message: "Too many requests, please slow down (limit is set to 10 requests per minute)",
          status: "danger",
          pos: "bottom-right"}
          )
          $('#datatableContainer').unblock();      
      }).DataTable()
      
  }
  else{
    this.setRowsVisibility(mdt)
  }
  var dtjs = $('#subtitles-table')
  var iii=0

 
  
  sessionStorage.setItem("set_movie_details", false)
// {
//   "initComplete":  function (settings, json) {
//       that.afterTableInitialization(settings,json)
//    }
//}

  
  
  //this.debug(`setChildRows   settings:${safeStringify(mdt.settings)}`)
  
  const detailRows = [];
  $('#subtitles-table tbody ').on( 'click','tr td.details-control em', function () {
    that.debug("click on cell, detailRows:"+safeStringify(detailRows));

   
        var mydata = $(this).data()
   
      //  if ($("#movie_details").length && sessionStorage.getItem("set_movie_details")!=false){
        if ($("#movie_details").length){
          that.debug("setChildRows setMovieDetails iii="+iii+" storage:"+sessionStorage.getItem("set_movie_details")+" mydata:"+safeStringify(mydata))
          that.setMovieDetails(mydata)
         // Storages.localStorage.set("set_movie_details", true);
          sessionStorage.setItem("set_movie_details", true)


          iii+=1
        }
        initTranslation();
     
    
  } );

//  const detailRows = [];
//  $('#subtitles-table tbody ').on( 'click','tr td.details-control em', function () {
//    that.debug("click on cell, detailRows:"+safeStringify(detailRows));
//    var tr = $(this).closest('tr');
//    var row = mdt.row( tr );
//    var idx = $.inArray( tr.attr('id'), detailRows );
//    
//    that.debug(`setChildRows click idx :${safeStringify(idx)} `)
//    if ( row.child.isShown() ) {
//        that.debug(`setChildRows click isShown`)
//        tr.removeClass( 'details' );
//        row.child.hide();
//
//        // Remove from the 'open' array
//        detailRows.splice( idx, 1 );
//    }
//    else {
//        tr.addClass( 'details' );
//       // that.debug(`setChildRows click  row.data :${safeStringify(row.data())} `)
//
//        var mydata = $(this).data()
//        row.child( format( mydata ) ).show();
//
//        that.debug(`setChildRows click !  isShown  -    row.child:${safeStringify(row.child)}`)
//
//      //  if ($("#movie_details").length && sessionStorage.getItem("set_movie_details")!=false){
//        if ($("#movie_details").length){
//          that.debug("setChildRows setMovieDetails iii="+iii+" storage:"+sessionStorage.getItem("set_movie_details"))
//          that.setMovieDetails(mydata)
//         // Storages.localStorage.set("set_movie_details", true);
//          sessionStorage.setItem("set_movie_details", true)
//
//
//          iii+=1
//        }
//        initTranslation();
//        // Add to the 'open' array
//        if ( idx === -1 ) {
//            detailRows.push( tr.attr('id') );
//        }
//    }
//  } );
  $.fn.dataTable.ext.errMode = 'none';

 mdt.on( 'draw', function () {
   that.debug("setChildRows  draw, trigger click ")
  // $.each( detailRows, function ( i, id ) {
  //    $('#'+id+' td.details-control').trigger( 'click' );
  //} );


} );
   

   mdt.on( 'init.dt', function () {
    that.debug( 'Table initialisation complete: '+new Date().getTime()+"   myCntr:"+myCntr  );
    var infobtns = $('tr td.details-control em')
    var ii=0
    var provider = document.getElementById('provider_select_input').selectedOptions[0].getAttribute('value')
    var title = document.getElementById('title_h_input').getAttribute('value')
    //for ( var i=0 ; i<8 ; i++ ) {
    //  mdt.column( i ).visible( true, false );
   // }
    dtjs.removeClass("opensubtitles")
    
  // mdt.on('error.dt', function (e, settings, techNote, message) {
  //   $.notify({
  //     message: "Too many requests, please slow down",
  //     status: "danger",
  //     pos: "bottom-right"}
  //     )
  //   //console.log('An error has been reported by DataTables: ', message);
  // })
  //
  
   
    

    if (provider=="opensubtitles"){
      dtjs.addClass(provider);
      mdt.columns(4).visible(true)
      mdt.columns(5).visible(true)
      mdt.columns(6).visible(true)
      mdt.columns(7).visible(true,true)
    }
    else if (provider=="subscene"){
      mdt.columns(4).visible(false)
      mdt.columns(5).visible(false)
      mdt.columns(6).visible(false)
      mdt.columns(7).visible(false)
    }
    else if (provider=="yifysubs"){
      mdt.columns(4).visible(false)
      mdt.columns(5).visible(false)
      mdt.columns(6).visible(false)
      mdt.columns(7).visible(false)

    }
    else if (provider=="podnapisi"){
      mdt.columns(4).visible(true)
      mdt.columns(5).visible(true)
      mdt.columns(6).visible(true)
      mdt.columns(7).visible(true,true)

    }
    else if (provider=="podnapisi_imdb"){
      
      mdt.columns(4).visible(true)
      mdt.columns(5).visible(true)
      mdt.columns(6).visible(true)
      mdt.columns(7).visible(true,true)
    }
    else if (provider=="addicted"){
      mdt.columns(4).visible(false)
      mdt.columns(5).visible(false)
      mdt.columns(6).visible(false)
      mdt.columns(7).visible(false,true)
      
    }

    that.setRowsVisibility(mdt)
  
//    mdt.draw()


   // if (infobtns.length>0 && title==""){
    if (infobtns.length>0){
      //var firstopt = $(infobtns[0]);
      var lastopt = $(infobtns[infobtns.length-1]);

      var data = lastopt.data();
      //firstopt.click()
      //if (data.featureDetails && !sessionStorage.getItem("set_movie_details")){
        if (data.featureDetails && myCntr==0){
        // if (data.featureDetails ){


       // if (data.featureDetails.title != title){
          that.debug("calling setMovieDetails from init #"+myCntr+" setRows for movie:"+data.featureDetails.title+" ii="+ii+"storage:"+sessionStorage.getItem("set_movie_details"))
          that.setMovieDetails(data)
          sessionStorage.setItem("set_movie_details", true)
          ii=ii+1
          myCntr++
       // }
      }


   //   console.log("openFirstInfo --- CLICK data+"+safeStringify(data));
    }
    else{
     // console.log("openFirstInfo no info loaded");
    }
  

    $('#datatableContainer').unblock();
    initTranslation();
    initBootstrap();
  })


  
  //that.openFirstInfo()
  

  
  //mdt.responsive.recalc()
  
 // mdt.on( 'initComplete', function () {
 //   var infobtns = $('tr td.details-control em')
 //   if (infobtns.length>0){
 //     $(infobtns[0]).click()
 //     console.log("openFirstInfo --- CLICK");
 //   }
 //   else{
 //     console.log("openFirstInfo no info loaded");
 //   }
 // })
//
  
 //  

  

 }

  connect() {
    // Ignore ghost events.
    if (!this.isBooting()) return

    var that = this
    const config = this.config || {}
    var filterdata=[]
  
   // this.debug("connect !!  csrf:"+csrf+"  data size:"+safeStringify(config.data.length))


    // You can alter the config here before the connect.

    var  query_languages = url.get('set_languages') 
    if (query_languages!=null){
      sessionStorage.setItem("search_languages",query_languages);
    }


    var search_languages = sessionStorage.getItem("search_languages")
    if (search_languages==undefined){
      search_languages=document.getElementsByTagName('html')[0].getAttribute('lang')
      if (search_languages==undefined){
        sessionStorage.setItem("search_languages",search_languages);
      }

    }
    var languages = search_languages.split(",")
    

    this.debug("hello ! connected - languages:"+search_languages);
    if (config.data){
      this.debug("connect !!  default contenz")
    // this.column( 1 )
    // .data()
    // .search( function ( value, index ) {
    //     return value == "en" ? true : false;
    // } );
      var origdata=config.data
      //this.debug("connect !!  origdata :"+safeStringify(origdata))
      $.each( origdata, function (i, elem ) {
        //    $('#'+id+' td.details-control').trigger( 'click' );
        //that.debug("loop, elem:"+safeStringify(elem))
        if (languages.includes(elem["language"])){
          filterdata.push(elem)
        }
          
      });
      config.data=filterdata
     // this.debug("connect !!  filterdata :"+safeStringify(filterdata))
    }
    else{
      this.debug("connect !!  ajax !!!")
    }
    //const dt = this
    //this.debug(dt.dataTable)
    //var api=dt.api()
    //api.columns().every( function () {
    //    this.debug($(this).val())
    //})
   
    $('#language_select_input').select2({
      theme: 'bootstrap4',
      maximumSelectionLength: 3      

    }); 
    
   
    $("#language_select_input").on('select2:select', function () {
      that.debug("list item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    
    $('#language_select_input').on("select2:unselecting", function(e) {
      var elem_id = e.params.args.data.id;
      that.debug("element removed "+elem_id);
      
      for( var i = 0; i < languages.length; i++){ 
        if ( languages[i] === elem_id) { 
          languages.splice(i, 1); 
        }
      }//=> [1, 2, 3, 4, 6, 7, 8, 9, 0]
      var ln_string=languages.join()
      sessionStorage.setItem("search_languages",ln_string );
      that.debug("language_select_input - dt is on, setItem: langs:"+ln_string)
      that.setLanguageSelect(languages)
      that.teardown() 
     // that.connect()

    });
     // Select the option with a value of '1'
   // $('#language_select_input').trigger('select') // Notify
   //this.setLanguageSelect(search_languages)
   $('#language_select_input').val(languages).trigger('change')
    // Call the super method to start up DataTables.
    super.connect()

    // works but bugs on reload
    
    if ($('#episode_select_input').children('option').length == 0 && $('#season_select_input').children('option').length > 0){
      $("#season_select_input").prop("selectedIndex", 0)
      var first_season = document.getElementById('season_select_input').selectedOptions[0].getAttribute("value")
      var episodes = document.getElementById('season_select_input').selectedOptions[0].getAttribute("data-episodes")
      this.setSeason(first_season,episodes)
      var first_imdbid = document.getElementById('episode_select_input').selectedOptions[0].getAttribute("value")
      
      var existing_imdbid=document.getElementById('episode_imdbid_h_input').getAttribute("value")
      if (existing_imdbid!=""){
        this.setEpisode(existing_imdbid)
        $("#episode_select_input option[value=" + existing_imdbid + "]").prop("selected",true);

      }
      else{
        this.setEpisode(first_imdbid)
      }
      
      
      this.debug('setChildRows in connect 1')
      this.setChildRows()
      //this.setQueryLinks()
      //this.teardown()
    }
    else{
      //this.setQueryLinks()
      this.debug('setChildRows in connect 2')
      
      this.setChildRows()
   
      this.setQueryLinks()
     
     
      // this.teardown()

    }
    


    // Any post connect actions here.
  }
  


  teardown() {
    // Any before or after teardown actions. Here we write to console.log.
    this.debug('finished', { dt: this })
    $('#datatableContainer').block({ message: null });
  
    
    // Call the super method to destroy the DataTable instance.
    this.debug("bye ! teardown");
    super.teardown()
    

  }


}


//
//// safely handles circular references
//JSON.safeStringify = (obj, indent = 2) => {
//  let cache = [];
//  const retVal = JSON.stringify(
//    obj,
//    (key, value) =>
//      typeof value === "object" && value !== null
//        ? cache.includes(value)
//          ? undefined // Duplicate reference found, discard key
//          : cache.push(value) && value // Store value in our collection
//        : value,
//    indent
//  );
//  cache = null;
//  return retVal;
//};
//
function downloadURI(uri, name) 
{
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.setAttribute('target', "_blank");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}




function format ( d ) {
  //console.log("format: d:"+safeStringify(d))
  var ret= "" ;
      //'<strong>Movie name: </strong><a href="/title/'+d.formatttedImdbid+'">'+d.moviename+'</a><br>'+
      //'<strong>imdbid:</strong> <a href="https://www.imdb.com/title/'+d.formatttedImdbid+'">'+d.formatttedImdbid+'</a><br>'+
      ret+='<strong><span data-localize="search.sort_LanguageName">Uploader</span>:</strong> '+d.language+'<br>';
      if (d.uploader_id!=""){
        ret+='<strong><span data-localize="search.uploader">Uploader</span>:</strong> '+d.uploader+'<br>'; 
      }
     // if (d.fps!=""){
     //  ret+='<strong>Framerate:</strong> '+d.fps+'<br>';
     // }
      if (d.comments!=""){
       ret+='<strong><span data-localize="search.comments">Comments</span>:</strong> '+d.comments+'<br>';
      }
      if (d.releases!="" && d.releases!=undefined){
        ret+='<strong><span data-localize="search.releases">Releases</span>:</strong> '+d.releases+'<br>';
       }
       if (d.orgLink!=""){
        ret+='<strong>View on opensubtitles.org:</strong> '+d.orgLink+'<br>';
      }
      if (d.comLink!=""){
       ret+='<strong>View on opensubtitles.com:</strong> '+d.comLink;
    }
    return ret;
}
