// TRANSLATION
// -----------------------------------

import Storages from 'js-storage';
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import url from 'url-parameters';


var pathPrefix = '/api/i18n'; // folder of json files
var STORAGEKEY = 'jq-appLang';
var savedLanguage;
var queryLanguage;
var useLanguage;
var defLang;

function initTranslation() {
    defLang=document.getElementsByTagName('html')[0].getAttribute('lang')
    queryLanguage = url.get('set_locale') 
  //  console.log("init translation");
    savedLanguage = Storages.localStorage.get(STORAGEKEY);
    
    if (queryLanguage!=null && queryLanguage!="null"){
        useLanguage=queryLanguage;
        Storages.localStorage.set(STORAGEKEY, queryLanguage);
       // console.log("init translation qs locale:"+queryLanguage+" defLang:"+defLang);
    }
    else if (savedLanguage!=null && savedLanguage!="null"){
        useLanguage=savedLanguage;
        //console.log("init translation NO qs locale, use saved:"+savedLanguage+" defLang:"+defLang);
    }
    else{
        useLanguage=defLang
    }
   // console.log("init translation qs locale:"+queryLanguage+" defLang:"+defLang+" useLanguage:"+useLanguage);

    i18next
        .use(i18nextXHRBackend)
        // .use(LanguageDetector)
        .init({
            fallbackLng: useLanguage || "en",
            backend: {
                loadPath: pathPrefix + '/{{ns}}-{{lng}}.json',
            },
            ns: ['site'],
            defaultNS: 'site',
            debug: false
        }, function(err, t) {
         
            if (useLanguage==null){
                useLanguage=defLang
            }
            setMenuValue(useLanguage);
               // initialize elements
               applyTranlations();
               // listen to language changes
               attachChangeListener();
        })

    function applyTranlations() {
        var list = [].slice.call(document.querySelectorAll('[data-localize]'))
        list.forEach(function(item) {
            var key = item.getAttribute('data-localize')
            var attr_key = item.getAttribute('data-attr-key')
            var attr_value = item.getAttribute('data-attr-value')
            var loctype = item.getAttribute('data-loctype')

            if (i18next.exists(key)) {
                var txt = i18next.t(key)
                if (loctype=="title"){
                    item.setAttribute("title",txt)
                }
                else{                    
                    if (typeof attr_key !== 'undefined' && attr_value !== 'undefined' && txt!== 'undefined'){
                        txt=txt.replace("%{"+attr_key+"}", attr_value);

                    }
                    item.innerHTML = txt
                }
            }
        })
    }

    function attachChangeListener() {
        var list = [].slice.call(document.querySelectorAll('[data-set-lang]'))
        list.forEach(function(item) {

            item.addEventListener('click', function(e) {
                if (e.target.tagName === 'A') e.preventDefault();
                var lang = item.getAttribute('data-set-lang')
                if (lang) {
                    i18next.changeLanguage(lang, function(err) {
                        if (err) console.log(err)
                        else {
                            applyTranlations();
                            Storages.localStorage.set(STORAGEKEY, lang);
                        }
                    });
                }
                activateDropdown(item)
                
            });

        })
    }
    function setMenuValue(lang){
        //console.log("setMenuValue need to set lang:"+lang);
        $('#locale-toggle').html(lang.toUpperCase());
    }

    function activateDropdown(item) {
        if (item.classList.contains('dropdown-item')) {
            //item.parentElement.previousElementSibling.innerHTML = item.innerHTML;
            //item.parentElement.previousElementSibling.innerHTML = savedLanguage.toUpperCase();
            //$('#locale-toggle').html(savedLanguage.toUpperCase());
            setMenuValue(item.getAttribute('data-set-lang'));
        }
    }

}

export default initTranslation;