// TOGGLE STATE
// -----------------------------------

import $ from 'jquery';
import Storages from 'js-storage';
import {  
    safeStringify    
  } from "helpers";
  
function initToggleState() {

    var $body = $('body');
    var toggle = new StateToggler();
    $('[data-toggle-style]')
        .on('click', function(e) {
            //console.log("toggle style");
            e.stopPropagation();
            var element = $(this),
            icon = element.children("em"),
            target_nav = element.data('target');
            //console.log("toggle style - target:"+target_nav);
            var $target_nav=$("#"+target_nav);
            var $target=$body;
            var $navcontainer=$("#topnavstretcher");
            var $icon=$(icon);
            if ($target.hasClass("layout-h")){
                
                //$icon.removeClass("icon-resize-full-alt");
                //$icon.addClass("icon-resize-small");
               // console.log("set full width");

                $target.addClass("layout-hf");
                $target.removeClass("layout-h");                

                toggle.addState("layout-hf","body");                
                toggle.removeState("layout-h","body");

                toggle.addState("container-fluid","#topnavstretcher");
                toggle.removeState("container","#topnavstretcher");
                                
                $navcontainer.addClass("container-fluid");                
                $navcontainer.removeClass("container");            
            }
            else{
                // $icon.addClass("icon-resize-full-alt");
                //$icon.removeClass("icon-resize-small");
                console.log("set narrow");
               
                $target.addClass("layout-h");
                $target.removeClass("layout-hf");    

                toggle.addState("layout-h","body");
                toggle.removeState("layout-hf","body");


                toggle.addState("container","#topnavstretcher");
                toggle.removeState("container-fluid","#topnavstretcher");
                        
                $navcontainer.removeClass("container-fluid");
                $navcontainer.addClass("container");

                
                
            }

    });
    $('[data-toggle-state]')
        .on('click', function(e) {
            // e.preventDefault();
            e.stopPropagation();
            var element = $(this),
                classname = element.data('toggleState'),
                target = element.data('target'),
                noPersist = !!element.attr('data-no-persist');

            // Specify a target selector to toggle classname
            // use body by default
            var $target = target ? $(target) : $body;

            if (classname) {
                if ($target.hasClass(classname)) {
                    $target.removeClass(classname);
                    if (!noPersist)
                        toggle.removeState(classname);
                } else {
                    $target.addClass(classname);
                    if (!noPersist)
                        toggle.addState(classname);
                }

            }

            // some elements may need this when toggled class change the content size
            if (typeof(Event) === 'function') { // modern browsers
                window.dispatchEvent(new Event('resize'));
            } else { // old browsers and IE
                var resizeEvent = window.document.createEvent('UIEvents');
                resizeEvent.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(resizeEvent);
            }
        });

}

// Handle states to/from localstorage
function StateToggler() {

    var STORAGE_KEY_NAME = 'jq-toggleState-alt';

  // this.setDefault(classname,namespace){
  //     var $elem = $(namespace);
  //     var data = Storages.localStorage.get(STORAGE_KEY_NAME+"-"+namespace);
  //     if (data == "" ){
  //         $elem.addClass("application-h");
  //     }
  //     else{
  //         
  //     }

  // }

    /** Add a state to the browser storage to be restored later */
    this.addState = function(classname,namespace) {
        var data = Storages.localStorage.get(STORAGE_KEY_NAME+"-"+namespace);
        if (data instanceof Array) data.push(classname);
        else data = [classname];
        Storages.localStorage.set(STORAGE_KEY_NAME+"-"+namespace, data);
    };
    /** Remove a state from the browser storage */
    this.removeState = function(classname,namespace) {
        var data = Storages.localStorage.get(STORAGE_KEY_NAME+"-"+namespace);
        if (data) {
            var index = data.indexOf(classname);
            if (index !== -1) data.splice(index, 1);
            Storages.localStorage.set(STORAGE_KEY_NAME+"-"+namespace, data);
        }
    };
    /** Load the state string and restore the classlist */
    this.restoreState = function(namespace,default_class="") {
        
        var $elem = $(namespace);
        var data = Storages.localStorage.get(STORAGE_KEY_NAME+"-"+namespace);
       // console.log("restorestate namespace:"+namespace+" default_class:"+default_class+" data:"+safeStringify(data));
        if (data instanceof Array){
            $elem.addClass(data.join(' '));
        }
        else{
            if(default_class!="" && (data=="" || data==null )){
                $elem.addClass(default_class);
            }
            else{
                $elem.addClass(data);
            }
        }
    };
};

export { StateToggler, initToggleState };