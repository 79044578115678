import { Controller } from "stimulus";


import {  
    safeStringify,    
    setImdbId    
  } from "helpers";
  
export default class extends Controller {

    

    initialize() {
        var that = this

    }
    
    toggle_season = event => {
        var sn = this.season_number
        var data = this.season_details
        console.log("toggle season:"+sn+" data:"+safeStringify(data))
    }

    get season_number(){
        return this.targets.find("season_number").value
    }
    get season_details(){
        return this.targets.find("season_number").selectedOptions[0].getAttribute("data-episodes")
    }


}