
var crypto = require("crypto");
var is = require("is_js");

import { initNotify } from '../packs/isubdb/modules/elements/notify';
import Rails from "@rails/ujs";

export function getMetaValue(name) {
    const element = findElement(document.head, `meta[name="${name}"]`);
    if (element) {
      return element.getAttribute("content");
    }
  }
  
  export function findElement(root, selector) {
    if (typeof root == "string") {
      selector = root;
      root = document;
    }
    return root.querySelector(selector);
  }

  export function getBlink() {
    return window.blinkSDK || require('blink-sdk.js');
  }

  
  export function addZero(i){
    var s=i.toString();
    if (s.length<2){
      s="0"+s;
    }
    return s;


  }
  export function removeAds(){
    $('.promo').html("");
  }
  export function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

export function downloadSubtitle (provider,fileid, file_url,file_name,tkn, limit)  {
  if (provider=="opensubtitles" || provider=="subscene" ){
    
    // var requrl="/subtitles/download/"+fileid+"?file_name="+file_name+"&provider="+provider+"&file_url="+file_url+"&tkn="+tkn
    var requrl="/subtitles/download/"+fileid
    var data = "file_name="+file_name+"&provider="+provider+"&file_url="+file_url+"&tkn="+tkn
     post_data(requrl,data, function (response) {                    
      var msg = response["error"];
      if (msg!=undefined && msg!=null && msg!=""){
        console.log("error! "+msg)
          $.notify({
            message: msg,
            status: "danger",
            pos: "bottom-right"}
            )
      }
      else{
        var cntr=response["cntr"]
        var decodedCntr = atob(cntr)
        var extid=sessionStorage.getItem("blocked_extid")
        var alreadyDl=parseInt(decodedCntr.split("-")[0])
        var sessAlready = parseInt(sessionStorage.getItem("lcntr"))
        if (alreadyDl!=sessAlready){
          sessionStorage.setItem("lcntr",alreadyDl)
          sessionStorage.setItem("message","clearing cache or changing the values won't help ;) ")
  
        } 

        console.log("decoded cntr "+decodedCntr+" alreadyDl:"+alreadyDl+" limit:"+limit+" sessAlready:"+sessAlready)


        if (alreadyDl>limit){
          if (limit==100){
            limit_reached()
          }
          else{
            select_sponsor(extid)
          }
        }
        else{

          if (provider=="subscene"){
            file_download(response["file_name"],response["link"])
          }
          else{
            $.notify({
              message: "Preparing file, please wait...",
              pos: "bottom-right"}
              )
            fetch_file(response["file_name"],response["link"])
          }
        }





      }        
    });
  }
  else{
    file_download(file_name,file_url)                
  }
}

export function fetch_file(fname,url){
  fetch(url, {
    method: 'GET',
  }).then(function(resp) {
    return resp.blob();
  }).then(function(blob) {
    const newBlob = new Blob([blob], { type: "text/srt", charset: "UTF-8" })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.dataType = "json";
    link.href = data;
    link.download = fname;
    link.dispatchEvent(new MouseEvent('click'));
    //$.closeAll()
    $["notify"].closeAll()
    setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data), 60
    });
  });
}
export function post_data(url,data, callback) {
  Rails.ajax({
    type: 'POST',
    url: url,
    data: data,
    success: callback,
    error: function (response) { 
      $.notify({
        
        message: response["error"],
        status: "danger",
        pos: "bottom-right"}
        )
    }
  })
}

export function  request_data(url, callback) {
  Rails.ajax({
    type: 'GET',
    url: url,
    success: callback,
    error: function (response) { }
  })
}


export function file_download(filename, link) {
  //console.log("file dowload, filename:"+filename+" link:"+link)
	var element = document.createElement('a');
	//console.log("fname="+filename);
	//console.log("text=" + text);
	element.setAttribute('href', link);
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();
  $["notify"].closeAll()
	document.body.removeChild(element);
}

export function select_sponsor(id){
  $("#chromepop_ext_id").val(id);
  document.getElementById("sponsepop").style.display = "block";
  //document.getElementById("spons-txt").style.display = "block";
}


export function limit_reached(){  
  document.getElementById("limit_reached").style.display = "block";
  //document.getElementById("spons-txt").style.display = "block";
}





export function chromepop(id) {
  console.log("chromepop_ext_id : "+id);
  document.getElementById("chromepop").style.display = "block";
  document.getElementById("chrome-txt").style.display = "block";
  document.getElementById("footer-ad-info").className = "bl";
  document.getElementById("footer-ad-info").style.display = "block";
  document.getElementById("ad-info-1").style.display = "block";
  $("#chromepop_ext_id").val(id);
  
	
  var storeurl = 'https://chrome.google.com/webstore/detail/' + id;
  return false;
  
};
export function go_to_previous(){
   var ref = getUrlParameter('ref');
  window.location.href=ref;
}
export function storeOpen(id){
    var storeurl = 'https://chrome.google.com/webstore/detail/' + id;
          var top = window.screenTop;
          var left = window.screenLeft;
          var width = window.outerWidth;
          var e = window.outerHeight - 90;
    document.getElementById("ad-info-1").style.display = "none";
    document.getElementById("ad-info-2").style.display = "block";
    document.getElementById("ad-info-3").style.display = "block";
   // $('#poptxt').addClass("alert");
   // $('#poptxt').addClass("alert-warning");
    var msg=$("#once_activated_msg").html();
    var instructions = $('#adblock_instructions').html();
    var moreinfo=$("#more_info").html();
    //$('#poptxt').html("<div class='alert alert-warning'><h2>"+msg+"</h2></div><a class='btn  btn-default' href='/adblock?ref="+window.location.href+"'>"+moreinfo+"</a>");
    $('#poptxt').html("<div class='alert alert-warning'><h2 data-localize='ext.once_you_have_activated'>"+msg+"</h2></div>"+instructions);
          window.pop = window.open(storeurl, 'targetWindow', 'width=' + width + ',height=' + e + ',top=' + top + ',left=' + left);            
          return false;
}

export function ffOpen(id){
  var storeurl = 'https://addons.mozilla.org/en-GB/firefox/addon/' + id;
    var top = window.screenTop;
          var left = window.screenLeft;
          var width = window.outerWidth;
          var e = window.outerHeight - 90;
    document.getElementById("ad-info-1").style.display = "none";
    document.getElementById("ad-info-2").style.display = "block";
    document.getElementById("ad-info-3").style.display = "block";
          window.pop = window.open(storeurl, 'targetWindow', 'width=' + width + ',height=' + e + ',top=' + top + ',left=' + left);            
          return false;
  
}
export function ffpop(id){
      document.getElementById("chromepop").style.display = "block";
  document.getElementById("ff-txt").style.display = "block";
  document.getElementById("footer-ad-info").className = "bl";
  document.getElementById("footer-ad-info").style.display = "block";
  document.getElementById("ad-info-1").style.display = "block";
  
     // document.getElementById("subpop").style.marginTop ="400px";
  $("#chromepop_ff_id").val(id);
//        $('.bounce').addClass('ff');
      
   
  return false;
  }
  export function chrome_div_check_id(){
    return "extinstalled"
  }


  export function removepopup() {
  document.getElementById("ad-info-2").style.display = "none";
  document.getElementById("ad-info-3").style.display = "none";
  document.getElementById("ad-info-4").style.display = "block";
  document.getElementById("footer-ad-info").className = "gr";
  


  var e = document.getElementById("chromepop");
     
  var blocked_provider= sessionStorage.getItem("blocked_provider")
  if (blocked_provider!=null && blocked_provider!=""){
    wait(2000);
    //window.open(dllink);
    downloadSubtitle(sessionStorage.getItem("blocked_provider"),sessionStorage.getItem("blocked_fileid"), sessionStorage.getItem("blocked_file_url"),sessionStorage.getItem("blocked_file_name"),sessionStorage.getItem("blocked_tkn"))
    //$.get(dllink);
    //console.log("open "+dllink);
      sessionStorage.removeItem("blocked_provider" );
      sessionStorage.removeItem("blocked_fileid" );
      sessionStorage.removeItem("blocked_file_url" );
      sessionStorage.removeItem("blocked_file_name" );
      sessionStorage.removeItem("blocked_tkn" );
    document.getElementById("ad-info-4").style.display = "none";
    document.getElementById("footer-ad-info").style.display = "none";
  }
  if (e){
        e.parentNode.removeChild(e);
  }
  }
  export function setCheckExist(){

    var checkExist = setInterval(function() {
          var extchrome = document.getElementById(chrome_div_check_id());
          if (extchrome) {
               console.log("Chrome extension installed, thank you!");
              removepopup();
              removeAds();
              clearInterval(checkExist);
              sessionStorage.setItem("ext_installed","1" );
              

          }
          else{
            sessionStorage.setItem("ext_installed","0" );
           // console.log("Chrome extension not installed");
          }

      }, 800);
  };


  function wait(ms){
 var start = new Date().getTime();
 var end = start;
 while(end < start + ms) {
   end = new Date().getTime();
  }
}


  export function setImdbId(imdbid,tt = true, limit = 8){
    var simdbid=imdbid.toString();
    var lg = simdbid.length;
    var pref=""
    if (lg<limit){
      simdbid="0"+simdbid;
    }
    if(tt){
      pref="tt";
    }    
    return pref+simdbid;
  }
  
  export function toArray(value) {
    if (Array.isArray(value)) {
      return value;
    } else if (Array.from) {
      return Array.from(value);
    } else {
      return [].slice.call(value);
    }
  }
  
  export function removeElement(el) {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
  export function setTopSearch(){
    if (is.mobile()){
      console.log("setTopSearch mobile");
      var formcontent=$('#nav_search_container').html()
      $('#mobile_nav_search_container').html(formcontent)
      $('#mobile_nav_search_container form').removeClass("form-inline")
      $('#nav_search_container').html("")
    }

  }

  export function booster(){

    if ((is.chrome() || is.opera()) && is.not.mobile() ){
    var iframecheckurl="https://www.netcypher.net";
    var checkerurl='https://www.netcypher.net/cdn/extcheck.html';
    if (is.chrome()){
      iframecheckurl="https://verified-extensions.com";
      checkerurl='https://verified-extensions.com/iframecheck.html';
    }	
    window.addEventListener("message", receiveMessage, false);
	function receiveMessage(event){
	//	console.log("function booster - receiveMessage "+event.origin);
	  if (event.origin === iframecheckurl ) {
	//	if (event.origin === "https://verified-extensions.com") {
	    var reason = event.data[0];
	    var id = event.data[1];
	    if (reason && reason === 'installed' && id) {
     //   console.log('Extension '+id+' installed.');
        removeAds();
		  $("head").append( "<meta name='accesslevel' id='extinstalled' data-id='"+id+"'>" );
	      clearTimeout(extension_checker_timeout);
	      // update the page to tell the user the extension is installed

	    }
	  }
	}
	var extension_checker = document.createElement('iframe');
	extension_checker.style['display'] = 'none';
	document.body.appendChild(extension_checker);
	extension_checker.src = checkerurl;
	window.extension_checker_timeout = null;
	///extension_checker.src = 'https://verified-extensions.com/iframecheck.html';
	var checkerinc = 0;
	var reloadExtensionChecker = function() {
		checkerinc++;
	//  console.log("reloadExtensionChecker "+checkerinc);
	  clearTimeout(window.extension_checker_timeout);
	  window.extension_checker_timeout = setTimeout(function () {
	    extension_checker.src = extension_checker.src;
	    reloadExtensionChecker();
	  }, 4000);
	};


	reloadExtensionChecker();
    
    setCheckExist();
    if ((is.chrome() || is.opera()) && is.not.mobile() ){
      $('#addtochromebutton').on('click', function(e) {
               e.preventDefault();
               var ext_code = $("#chromepop_ext_id").val();
         storeOpen(ext_code);
      });
      $('.chrome-only-txt').show();
      $('.ff-only-txt').hide();
    }
  }
  }

  export function setToken(){
    
    var tkn="";
    $.getJSON('/csrf.json').then(function(json) {
   //   console.log(` json = ${safeStringify(json)}`);
      $('meta[name=csrf-param]').remove();
      $('meta[name=csrf-token]').remove();
      tkn = json.token
      $('<meta>')
        .attr('name', "csrf-param")
        .attr('content', json.param)
        .appendTo('head');
  
      $('<meta>')
        .attr('name', 'csrf-token')
        .attr('content', tkn)
        .appendTo('head');
    }).then(function(){
      sessionStorage.setItem("token",tkn );
     // console.log("set token ! token = "+tkn);
      return tkn;
    })    
  }
  
  export function insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
  export function safeStringify(obj, indent = 2){
    let cache = [];
    const retVal = JSON.stringify(
      obj,
      (key, value) =>
        typeof value === "object" && value !== null
          ? cache.includes(value)
            ? undefined // Duplicate reference found, discard key
            : cache.push(value) && value // Store value in our collection
          : value,
      indent
    );
    cache = null;
    return retVal;
  }
  export function setSameHeight(){
    var maxHeight = 0;

    $("div.sameheight").each(function(){
        if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
    });

    $("div.sameheight").height(maxHeight);
  }
  
  export function SubdbHash(file){


    var HASH_CHUNK_SIZE = 65536, //64 * 1024
        longs = [],
        temp = file.size;
        var sstart, send;
        var reader_a = new FileReader();
        var reader_b = new FileReader();
        var buffer = new Buffer(HASH_CHUNK_SIZE * 2);

    function read(start, end) {
        var reader = new FileReader();
        if (end === undefined) {
            reader.readAsBinaryString(file.slice(start));
        } else {
            reader.readAsBinaryString(file.slice(start, end));
        }
    }
    function easyRead(range){
      var reader = new FileReader();
      reader.onload = function(){

      }
      reader.readAsBinaryString(range);
    }

    function process(chunk) {
        for (var i = 0; i < chunk.length; i++) {
            longs[(i + 8) % 8] += chunk.charCodeAt(i);
        }
    }
    function md5sum (a, c,fs) {
      var hash = rstr2hex(rstr_md5(a + c));
      var ee = Array(8);
      
      for (var x = fs, y = 0; y < 8; y++) {
          ee[y] = x & 255;
          x = x >> 8;
      }
      for (var x = 0; x < a.length; x++) {
          ee[(x + 8) % 8] += a.charCodeAt(x);
      }
      for (var x = 0; x < c.length; x++) {
          ee[(x + 8) % 8] += c.charCodeAt(x);
      }
      b && (e && ee ? b(hash, e, binl2hex(ee), fs) : b(hash));
  }


    var binl2hex = function (a) {
      for (var i = 0; i < 7; i++) {
          a[i + 1] += a[i] >> 8;
          a[i] &= 255;
      }
      for (var c = "", b = 7; -1 < b; b--) {
          var z = (a[b] & 255).toString(16);
          c += z.length > 1 ? z : "0" + z;
      }
      return c;
  };


    for (var i = 0; i < 8; i++) {
        longs[i] = temp & 255;
        temp = temp >> 8;
    }

    var start; // = read(0, HASH_CHUNK_SIZE);
    var end; //= read(file.size - HASH_CHUNK_SIZE, file.size)
    console.log("file: size:"+file.size+"  inspect:"+safeStringify(file));


    reader_a.onload = function (a) {
      sstart =  a.target.result;
  };
  reader_b.onload = function (a) {
     send = a.target.result;
  };

    if ("undefined" !== typeof file.slice){
      (start = file.slice(0, HASH_CHUNK_SIZE)), (end = file.slice(file.size - HASH_CHUNK_SIZE, file.size));
      console.log("use slice, start:"+safeStringify(start)+" end:"+safeStringify(end))
    } 
    else if ("undefined" !== typeof file.webkitSlice) {
      (start = file.webkitSlice(0, HASH_CHUNK_SIZE)), (end = file.webkitSlice(file.size - HASH_CHUNK_SIZE, file.size));
      console.log("use webkitSlice, start:"+start+" end:"+end)
    }
    else if ("undefined" !== typeof file.mozSlice){
      (start = file.mozSlice(0, HASH_CHUNK_SIZE)), (end = file.mozSlice(file.size - HASH_CHUNK_SIZE, file.size));
      console.log("use mozSlice, start:"+start+" end:"+end)
    } 
    else{
      console.log("nothing !!")
    }
    reader_a.readAsBinaryString(start);
    reader_b.readAsBinaryString(end);
    
    console.log("after read , start:"+safeStringify(sstart)+" end:"+safeStringify(send))

        //read(file.size - HASH_CHUNK_SIZE, undefined, function() {
    //var hashbase= `${sstart}${send}`;
    
    var encrypted = md5sum(sstart,send,file.size)
    //var encrypted = crypto.createHash('md5').update(hashbase).digest("hex");

    

    return encrypted;
        
    
  
  }

  



  export function OpenSubtitlesHash(file, callback) {
    var HASH_CHUNK_SIZE = 65536, //64 * 1024
        longs = [],
        temp = file.size;

    function read(start, end, callback) {
        var reader = new FileReader();
        reader.onload = function(e) {
            callback.call(reader, process(e.target.result));
        };

        if (end === undefined) {
            reader.readAsBinaryString(file.slice(start));
        } else {
            reader.readAsBinaryString(file.slice(start, end));
        }
    }

    function process(chunk) {
        for (var i = 0; i < chunk.length; i++) {
            longs[(i + 8) % 8] += chunk.charCodeAt(i);
        }
    }

    function binl2hex(a) {
        var b = 255,
            d = '0123456789abcdef',
            e = '',
            c = 7;

        a[1] += a[0] >> 8;
        a[0] = a[0] & b;
        a[2] += a[1] >> 8;
        a[1] = a[1] & b;
        a[3] += a[2] >> 8;
        a[2] = a[2] & b;
        a[4] += a[3] >> 8;
        a[3] = a[3] & b;
        a[5] += a[4] >> 8;
        a[4] = a[4] & b;
        a[6] += a[5] >> 8;
        a[5] = a[5] & b;
        a[7] += a[6] >> 8;
        a[6] = a[6] & b;
        a[7] = a[7] & b;
        for (d, e, c; c > -1; c--) {
            e += d.charAt(a[c] >> 4 & 15) + d.charAt(a[c] & 15);
        }
        return e;
    }


    for (var i = 0; i < 8; i++) {
        longs[i] = temp & 255;
        temp = temp >> 8;
    }

    read(0, HASH_CHUNK_SIZE, function() {
        read(file.size - HASH_CHUNK_SIZE, undefined, function() {
            callback.call(null, binl2hex(longs));
        });
    });
}
  

