// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("modernizr/modernizr.custom.js");


require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
// The controller will call: window.jQuery(table).DataTable(config)


// These examples use bootstrap4 and the scroller plugin.
// See https://datatables.net/download for more options.

//require('imports-loader?define=>false!datatables.net-bs4')(window, $)



//
//require( 'datatables.net') 
//require( 'datatables.net-bs4' )
//$.fn.dataTable = DataTable;
//$.fn.dataTableSettings = DataTable.settings;
//$.fn.dataTableSettings = DataTable.settings;
//$.fn.dataTableExt = DataTable.ext;
//DataTable.$ = $;
//// 
//$.fn.DataTable = function ( opts ) {
//    return $(this).dataTable( opts ).api();
//};


//--- Bootstrap
import 'bootstrap';
//require('imports-loader?define=>false!datatables.net')(window, $)
//require('imports-loader?define=>false!datatables.net-select')(window, $)


import appInit from './isubdb/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
